@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300);

/*Ignore me, I'm just page styling*/
// html {font-family: 'Roboto', sans-serif;font-size:16px;line-height:1em;height:100%;}
// body {
//   background: #ffffff;
//   background: -moz-linear-gradient(-45deg, #ffffff 0%, #ffffff 50%, #F2F2F2 50%, #F2F2F2 50%, #F2F2F2 100%);
//   background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#ffffff), color-stop(50%,#ffffff), color-stop(50%,#F2F2F2), color-stop(50%,#F2F2F2), color-stop(100%,#F2F2F2));
//   background: -webkit-linear-gradient(-45deg, #ffffff 0%,#ffffff 50%,#F2F2F2 50%,#F2F2F2 50%,#F2F2F2 100%);
//   background: -o-linear-gradient(-45deg, #ffffff 0%,#ffffff 50%,#F2F2F2 50%,#F2F2F2 50%,#F2F2F2 100%);
//   background: -ms-linear-gradient(-45deg, #ffffff 0%,#ffffff 50%,#F2F2F2 50%,#F2F2F2 50%,#F2F2F2 100%);
//   background: linear-gradient(135deg, #ffffff 0%,#ffffff 50%,#F2F2F2 50%,#F2F2F2 50%,#F2F2F2 100%);
// }

// h1 {font-size:1.5em;margin-bottom:55px;}

#social-platforms {
  position:relative;
  top:100px;
  top:25vh;
  font-size:1rem;
  text-align:center;
  height:250px;
  overflow:hidden;
}

/*Pen code from this point on*/
.btn-social {
  clear:both;
  white-space:nowrap;
  font-size:.8em;
  display:inline-block;
  border-radius:5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  margin:2px;
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  transition:all .5s;
  overflow:hidden
}

.btn-social:hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.45);
}

.btn-social:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}

.btn-social > span,.btn-social-icon > i {
  float:left;
  padding:13px;
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  transition:all .5s;
  line-height:1em
}

.btn-social > span {
  padding:14px 18px 16px;
  white-space:nowrap;
  color:#FFF;
  background:#b8b8b8
}

.btn-social:focus > span {
  background:#9a9a9a
}

.btn-social-icon > i {
  border-radius:5px 0 0 5px;
  position:relative;
  width:13px;
  text-align:center;
  font-size:1.25em;
  color:#fff;
  background:#212121
}

.btn-social-icon > i:after {
  content:"";
  border:8px solid;
  border-color:transparent transparent transparent #222;
  position:absolute;
  top:13px;
  right:-15px
}

.btn-social-icon:hover > i,.btn-social-icon:focus > i {
  color:#FFF
}

.btn-social-icon > span {
  border-radius:0 5px 5px 0
}

/*Facebook*/
.btn-social-facebook:hover > i,.btn-social-facebook:focus > i {
  color:#3b5998
}

.btn-social-facebook > span {
  background:#3b5998
}

/*Twitter*/
.btn-social-twitter:hover > i,.btn-social-twitter:focus > i {
  color:#55acee
}

.btn-social-twitter > span {
  background:#55acee
}

/*Google*/
.btn-social-googleplus:hover > i,.btn-social-googleplus:focus > i {
  color:#dd4b39
}

.btn-social-googleplus > span {
  background:#dd4b39
}

/*Pinterest*/
.btn-social-pinterest:hover > i,.btn-social-pinterest:focus > i {
  color:#cb2028
}

.btn-social-pinterest > span {
  background:#cb2028
}

/*LinkedIn*/
.btn-social-linkedin:hover > i,.btn-social-linkedin:focus > i {
  color:#007bb6
}

.btn-social-linkedin > span {
  background:#007bb6
}

