body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-1_5 {
  margin-right: 0.5rem !important;
}

.ml-1_5 {
  margin-left: 0.5rem !important;
}

@media (min-width: 768px) {
  .md-ml-1_5 {
    margin-left: 0.5rem !important;
  }
  .md-mr-1_5 {
    margin-right: 0.5rem !important;
  }
  .md-mt-1_5 {
    margin-top: 0.5rem !important;
  }
  .md-mb-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .md-mt-3 {
    margin-top: 1rem !important;
  }
  .md-mb-3 {
    margin-bottom: 1rem !important;
  }
}

@media (min-width: 992px) {
  .lg-ml-1_5 {
    margin-left: 0.5rem !important;
  }
  .lg-mr-1_5 {
    margin-right: 0.5rem !important;
  }
  .lg-mt-1_5 {
    margin-top: 0.5rem !important;
  }
  .lg-mb-1_5 {
    margin-bottom: 0.5rem !important;
  }
  .lg-mt-3 {
    margin-top: 1rem !important;
  }
  .lg-mb-3 {
    margin-bottom: 1rem !important;
  }
  .lg-mt-0 {
    margin-top: 0px !important;
  }
  .lg-mb-0 {
    margin-bottom: 0px !important;
  }
  
}