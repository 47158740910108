.text-shadow {
    text-shadow: 5px 3px 2px #797979a4;
    z-index: 1;
}

.big-font {
    font-size: 5em;
}

.bg-teaser {
    background-image: linear-gradient(rgba(248, 249, 250, 0.95), rgba(248, 249, 250, 0.95)),
                  url("../../static/bg-image4.jpg");
    // background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
}

