.logo-texture {
    height : 26px;
    width: 26px;
    background: url(../../static/T-letter.svg);
    background-repeat: no-repeat;
}


@media only screen and (max-width: 960px) {
    .auto-margin {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    }

